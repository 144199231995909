import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postTask, getTypes, getProducts } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactDrawer from 'react-drawer';

const TasksCreate = () => {
  const history = useHistory();
  document.title = "Create Task | kerino - React Admin & Dashboard Template";

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [types, settypes] = useState([]);
  const [name, setname] = useState("");
  const [status, setstatus] = useState("");
  const [products, setproducts] = useState([]);
  const [type, settype] = useState("");
  const [description, setdescription] = useState("");
  const [Loanopen, setLoanOpen] = useState(false); // Drawer state
  const [Loanproducts, setLoanproducts] = useState([]); // Selected products state
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered products state
  const [search, setSearch] = useState(""); // Search input state

  const startDateChange = (date) => setstartDate(date);
  const endDateChange = (date) => setendDate(date);

  useEffect(() => {
    getTypes().then((res) => settypes(res));
  }, []);

  useEffect(() => {
    getProducts().then(res => setproducts(res));
  }, []);

  useEffect(() => {
    setFilteredProducts(
      products.filter(p => 
        p.name.toLowerCase().includes(search.toLowerCase()) && 
        p.usageType !== "PURCHASED-MATERIAL"
      )
    );
  }, [search, products]);

  const createTasks = (products) => {
    //console.log(products)
    products.map((prd,i) => {
      const productReqs = JSON.parse(prd.requirements)
      productReqs.map((reqs) => {
        console.log(reqs)
        createTask(reqs, prd)

      })



    })


  }

  const createTask = (productReqs, prd,i) => {

    const payload = {
      "resource": [
        {
          "type": productReqs.typid,
          "materials": JSON.stringify(productReqs.materials),
          "customer_name": "KERINO-INTERNAL",
          "status": "PENDING",
          "orderref": 999999,
          "productid": prd.id,
          "name": prd.name,
          "description":description,
          "quantity": prd.quantity,
          "expectedDate": startDate,
          "customer_id_ref": 888888,
          "seq": i,
        }
      ],
    };

    console.log(payload)

    postTask(payload).then((res) => {
      if (res && res.resource) {
        history.push("/tasks");
      }
    });
  };

  // Function to handle quantity change
  const handleQuantityChange = (index, quantity) => {
    const updatedProducts = Loanproducts.map((product, i) =>
      i === index ? { ...product, quantity } : product
    );
    setLoanproducts(updatedProducts);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tasks" breadcrumbItem="Create Task" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Task</CardTitle>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label htmlFor="taskname" className="col-form-label col-lg-2">
                            Task Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              className="form-control"
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">Task Description</Label>
                          <Col lg="10">
                            <Input
                              id="description"
                              name="description"
                              type="textarea"
                              value={description}
                              onChange={(e) => setdescription(e.target.value)}
                              className="form-control"
                              placeholder="Enter Task Description..."
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">Task Date</Label>
                          <Col lg="10">
                            <Row>
                              <Col md={6}>
                                <DatePicker className="form-control" selected={startDate} onChange={startDateChange} />
                              </Col>
                              <Col md={6}>
                                <DatePicker className="form-control" selected={endDate} onChange={endDateChange} />
                              </Col>
                            </Row>
                          </Col>
                        </FormGroup>



                        {/* Button to open product drawer */}
                        <Button color="primary" onClick={() => setLoanOpen(true)}>
                          Select Products
                        </Button>

                        {/* Product drawer */}
                        <ReactDrawer
                          open={Loanopen}
                          position="left"
                          onClose={() => setLoanOpen(false)}
                          style={{ width: '300px' }}
                        >
                          <Row style={{ margin: 10 }}>
                            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search Products"></Input>
                          </Row>
                          <div style={{ margin: 10, maxHeight: '90%', overflowY: 'auto' }}>
                            {filteredProducts && filteredProducts.map((prd, i) => (
                              <Row
                                key={i}
                                onClick={() => {
                                  Object.assign(prd, { quantity: 1 }); // Set default quantity to 1
                                  setLoanproducts([...Loanproducts, prd]); // Add product to selected products list
                                  setLoanOpen(false); // Close drawer
                                }}
                                style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: i % 2 === 0 ? '#EBEBF6' : '#fff', borderTop: '3px solid #ddd' }}
                              >
                                <Col lg="6">{prd.name}</Col>
                                <Col lg="3">{prd.salesprice}</Col>
                                <Col lg="2">{prd.stock}</Col>
                              </Row>
                            ))}
                          </div>
                        </ReactDrawer>

                        {/* Display selected products */}
                        <CardTitle className="mt-4">Selected Products</CardTitle>
                        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                          {Loanproducts.map((product, index) => (
                            <Row key={index} style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                              <Col lg="4">{product.name}</Col>
                              <Col lg="3">{product.salesprice}</Col>
                              <Col lg="2">
                                <Input
                                  type="number"
                                  value={product.quantity}
                                  min="1"
                                  onChange={(e) => handleQuantityChange(index, e.target.value)}
                                />
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button onClick={() => createTasks(Loanproducts)} color="primary">
                        Create Task
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

TasksCreate.propTypes = {
  history: PropTypes.object,
};

export default TasksCreate;
