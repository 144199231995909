import React, { useState, useEffect } from "react";
import {
    Container,
    Button,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getMaterials, getTasks, getProducts } from "../../helpers/api_helper";
import PLYSIMO from "./PLYSIMO";
import SingleMulti from "./SingleMulti";
import MyDrawer from "./drawer";

const Pressa = (props) => {
    const { match: { params } } = props;

    document.title = "Section ";

    const [type, setType] = useState();
    const [tasks, setTasks] = useState([]);
    const [sectiontasks, setsectiontasks] = useState([]);
    const [materialsList, setMaterialsList] = useState([]);
    const [filteredMaterials, setFilteredMaterials] = useState([]);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedReplace, setselectedReplace] = useState({});
    const [selectedReplaceindex, setselectedReplaceindex] = useState("");
    const [isInactive, setIsInactive] = useState(false);

    const dispatch = useDispatch();



    useEffect(() => {
        const fetchTasks = async () => {
            const allTasks = await getTasks(params.section);
            const filtered = filterPayload(allTasks, type);
            setTasks(allTasks);
            setsectiontasks(filtered);
    
        };

        fetchTasks();
    }, [type]);

    useEffect(() => {
        getMaterials().then(res => setMaterialsList(res));
        setType(params.section);
    }, []);

    useEffect(() => {
        const searchRegex = new RegExp(search, 'i');
        const filtered = search
            ? materialsList.filter((cust) => searchRegex.test(cust.name) || searchRegex.test(cust.description))
            : materialsList.filter((cust) => cust.stock > 0 && cust.usageType !== "COMPLETE-PRODUCT");
        setFilteredMaterials(filtered);
    }, [search, materialsList]);

    useEffect(() => {
        let inactivityTimeout;

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimeout);
            setIsInactive(false);
            inactivityTimeout = setTimeout(() => {
                setIsInactive(true);
            }, 60000); // 1 minute of inactivity
        };

        window.addEventListener("mousemove", resetInactivityTimer);
        window.addEventListener("keydown", resetInactivityTimer);
        window.addEventListener("scroll", resetInactivityTimer);
        window.addEventListener("click", resetInactivityTimer);

        resetInactivityTimer();

        return () => {
            window.removeEventListener("mousemove", resetInactivityTimer);
            window.removeEventListener("keydown", resetInactivityTimer);
            window.removeEventListener("scroll", resetInactivityTimer);
            window.removeEventListener("click", resetInactivityTimer);
            clearTimeout(inactivityTimeout);
        };
    }, []);

    useEffect(() => {
        if (isInactive) {
            console.log("Refreshing tasks due to inactivity");
            refreshTasks();
        }
    }, [isInactive]);

    const filterPayload = (payload, type) => {
        return payload.filter((item) => {
            if (item.type === type && item.seq === 1) {
                return true;
            } else if (item.type === type && item.seq > 1 && (item.status === "PENDING" || item.status === "STOCK-RECEIVED")) {
                const previousTask = payload.find(
                    (prevItem) => prevItem.orderref === item.orderref && prevItem.seq === item.seq - 1 && prevItem.status === "FINISHED"
                );
                return !!previousTask;
            }
            return false;
        });
    };

    const refreshTasks = async () => {
        const allTasks = await getTasks(params.section);
        const filtered = filterPayload(allTasks, type);
        setTasks(allTasks);
        setsectiontasks(filtered);
    };

    const getLStock = async (id) => {
        const stock = await getProducts(id).then(res => res[0]);
        return stock?.stock || 0;
    };

    return (
        <React.Fragment>
            <div>
                <Container fluid>
                    
                    <h5>SECTION {params.section} </h5>

                    <Button color="primary" onClick={refreshTasks}>Refresh Tasks</Button>
                    {type === "ΠΛΥΝΤΗΡΙΟ" ? (
                        <PLYSIMO
                            type={type}
                            refresh={refreshTasks}
                            getLStock={getLStock}
                            sectiontasks={sectiontasks}
                            allTasks={tasks}
                        />
                    ) : (
                        <SingleMulti
                            section={params.section}
                            selectedReplace={selectedReplace}
                            openDrawer={open}
                            setopenDrawer={(e, matr) => {
                                setOpen(e);
                                setselectedReplaceindex(matr);
                            }}
                            refreshTasks={refreshTasks}
                            type={type}
                            refresh={refreshTasks}
                            setsectionTasks={setsectiontasks}
                            getLStock={getLStock}
                            sectiontasks={sectiontasks}
                            allTasks={tasks}
                        />
                    )}
                </Container>
                <MyDrawer
                    open={open}
                    position={'right'}
                    onClose={() => setOpen(false)}
                    style={{ width: 'calc(100% + 20px)' }}
                    filteredMaterials={filteredMaterials}
                    selectedStep={(e) => setselectedReplace({ toreplace: selectedReplaceindex, new: e })}
                    setSearch={setSearch}
                />
            </div>
        </React.Fragment>
    );
};

Pressa.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            section: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default Pressa;
