import React from 'react';
import PropTypes from 'prop-types';

const CustomerList = ({ customers }) => {
  // Function to calculate the total bill for a single customer.
  const calculateTotalBill = (orders) => {
    return orders.reduce((acc, order) => {
      try {
        // Parse the JSON string of `totals` to access `totalProductsAmmount`.
        const parsedOrderTotals = JSON.parse(order.totals);
        return acc + (parsedOrderTotals.totalProductsAmmount || 0);
      } catch (error) {
        console.error('Error parsing orderTotals:', error);
        return acc;
      }
    }, 0);
  };

  // Function to calculate sales per category for a given order.
  const calculateSalesPerCategory = (products) => {
    const salesPerCategory = {};

    // Iterate over products and accumulate sales by category.
    products.forEach((product) => {
      const { category, quantity, salesprice } = product;
      const sales = quantity * salesprice;

      // Accumulate the sales in the respective category.
      if (salesPerCategory[category]) {
        salesPerCategory[category] += sales;
      } else {
        salesPerCategory[category] = sales;
      }
    });

    return salesPerCategory;
  };

  // Function to calculate total sales per category across all customers.
  const calculateTotalSalesForAllCategories = (customers) => {
    const totalSalesPerCategory = {};

    customers.forEach((customer) => {
      customer.orders.forEach((order) => {
        try {
          const products = JSON.parse(order.products);
          const salesPerCategory = calculateSalesPerCategory(products);

          // Combine the sales per category with the total sales per category.
          Object.entries(salesPerCategory).forEach(([category, sales]) => {
            if (totalSalesPerCategory[category]) {
              totalSalesPerCategory[category] += sales;
            } else {
              totalSalesPerCategory[category] = sales;
            }
          });
        } catch (error) {
          console.error('Error parsing products:', error);
        }
      });
    });

    return totalSalesPerCategory;
  };

  // Calculate the total bill for all customers.
  const fullTotalBill = customers.reduce((acc, customer) => {
    return acc + calculateTotalBill(customer.orders);
  }, 0);

  // Calculate total sales per category for all customers.
  const totalSalesPerCategory = calculateTotalSalesForAllCategories(customers);

  // Calculate commission per category (3% of total sales per category).
  const commissionPerCategory = Object.fromEntries(
    Object.entries(totalSalesPerCategory).map(([category, totalSales]) => [
      category,
      (totalSales * 0.03),
    ])
  );

  // Calculate the total of all commissions.
  const totalCommission = Object.values(commissionPerCategory).reduce((acc, commission) => acc + commission, 0);

  return (
    <div>
      <h2>Customer List</h2>
      <ul>
        {customers.map((customer, index) => {
          const totalBill = calculateTotalBill(customer.orders);

          return (
            <li key={index}>
              <strong>Name:</strong> {customer.name} <br />
              <strong>Order Count:</strong> {customer.orders.length} <br />
              <strong>Total Bill:</strong> ${totalBill.toFixed(2)} <br />
              <ul>
                {customer.orders.map((order, orderIndex) => {
                  try {
                    const products = JSON.parse(order.products); // Parse the products JSON string
                    const salesPerCategory = calculateSalesPerCategory(products);

                    return (
                      <li key={orderIndex}>
                        <strong>Order Total:</strong> ${JSON.parse(order.totals).totalProductsAmmount.toFixed(2)}
                        <ul>
                          {Object.entries(salesPerCategory).map(([category, sales], categoryIndex) => (
                            <li key={categoryIndex}>
                              <strong>Category:</strong> {category}, <strong>Sales:</strong> ${sales.toFixed(2)}
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  } catch (error) {
                    console.error('Error parsing products:', error);
                    return <li key={orderIndex}>Error displaying order details.</li>;
                  }
                })}
              </ul>
            </li>
          );
        })}
      </ul>
      <h3>Full Total of All Bills: ${fullTotalBill.toFixed(2)}</h3>
      <h3>Total Sales Per Category (All Customers):</h3>
      <ul>
        {Object.entries(totalSalesPerCategory).map(([category, sales], index) => (
          <li key={index}>
            <strong>Category:</strong> {category}, <strong>Total Sales:</strong> ${sales.toFixed(2)}
          </li>
        ))}
      </ul>
      <h3>Total Commission Per Category (All Customers):</h3>
      <ul>
        {Object.entries(commissionPerCategory).map(([category, commission], index) => (
          <li key={index}>
            <strong>Category:</strong> {category}, <strong>Commission (3%):</strong> ${commission.toFixed(2)}
          </li>
        ))}
      </ul>
      <h3>Total Commission: ${totalCommission.toFixed(2)}</h3>
    </div>
  );
};

// Define the PropTypes for the component.
CustomerList.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          totals: PropTypes.string.isRequired, // JSON string of the totals
          products: PropTypes.string.isRequired, // JSON string of products list
        })
      ).isRequired,
    })
  ).isRequired,
};

export default CustomerList;
