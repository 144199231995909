import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap';

const MaterialModal = ({ products ,isOpen ,setisOpen}) => {
  const [modal, setModal] = useState(false);

  return (
    <div>


      <Modal isOpen={isOpen} >
        <ModalHeader>Stock Status</ModalHeader>
        <ModalBody>
          {products[0] && products.map((product, productIndex) => (
            <div key={productIndex} className="mb-4">
              <h5>{product.productName}</h5>
              {product.missedStock.length > 0 ? (
                <ListGroup>
                  {product.missedStock.map((missed, index) => (
                    <ListGroupItem key={index} className="text-danger">
                      {missed.name} - Missing: {missed.missingStock}, Stock Available: {missed.stock}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : (
                <ListGroup>
                  {product.materials.map((material, index) => (
                    <ListGroupItem key={index} className="text-success">
                      {material.name} - Quantity Required: {material.quantity}, Stock Available: {material.stock} - STOCK-OK
                    </ListGroupItem>
                  ))}
                </ListGroup>
              )}
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setisOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

// Define prop types for type checking
MaterialModal.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productName: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      materials: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
          sumQuantity: PropTypes.number.isRequired,
          stock: PropTypes.number.isRequired,
          stockStatus: PropTypes.string.isRequired,
        })
      ).isRequired,
      missedStock: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          stock: PropTypes.number.isRequired,
          missingStock: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  isOpen:PropTypes.bool,
  setisOpen:PropTypes.func
};

export default MaterialModal;
