import React from 'react';
import { jsPDF } from "jspdf"; // Ensure you have jsPDF installed


export const sendEmailOffer = async (order) => {
    try {
      const pdf = new jsPDF();

      // Generate the PDF content (this is where you'd customize your content)
      pdf.text('Invoice Details', 20, 20);
      pdf.text(`Order ID: ${order.id}`, 20, 30);
      pdf.text(`Customer Name: ${order.name}`, 20, 40);

      // Assuming order.products is an array of products
      const products = JSON.parse(order.products);
      let yPosition = 50; // Starting position for products
      products.forEach((product, index) => {
        pdf.text(`Product ${index + 1}: ${product.name}`, 20, yPosition);
        yPosition += 10;
        pdf.text(`Quantity: ${product.quantity}`, 20, yPosition);
        yPosition += 10;
        pdf.text(`Price: ${product.salesprice}`, 20, yPosition);
        yPosition += 20; // Extra space after each product
      });

      // Output the PDF and prepare the email
      const pdfOutput = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfOutput);
      const mailtoLink = `mailto:aspirtakis@gmail.com?subject=Invoice Offer&body=Please find the attached invoice.&attachment=${pdfUrl}`;

      // Open the mail client
      window.open(mailtoLink);
    } catch (error) {
      console.error("Error generating PDF or sending email:", error);
    }
  };