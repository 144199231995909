import React from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from "recharts";

const SalesPaymentsBarChart = ({ totalOrders, totalPaymentsInbound, customerTotal }) => {
  const data = [
    { name: "Sales", value: totalOrders || 0 },
    { name: "Payments", value: totalPaymentsInbound || 0 },
    { name: "Balance", value: customerTotal || 0 }
  ];

  return (
    <BarChart width={200} height={200} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
  );
};

// PropTypes validation
SalesPaymentsBarChart.propTypes = {
  totalOrders: PropTypes.number.isRequired,
  totalPaymentsInbound: PropTypes.number.isRequired,
  customerTotal: PropTypes.number.isRequired
};

export default SalesPaymentsBarChart;
