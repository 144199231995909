import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function InvoiceTemplate({ order }) {
  const { totalOrder, totalItems, totalVat } = JSON.parse(order.totals);
  const products = JSON.parse(order.products);
  const loans = JSON.parse(order.loans);
  const services = JSON.parse(order.services);

  const printRef = useRef(null);

  const handlePrint = () => {
    window.print(); // This triggers the print dialog
  };

  // Translation logic based on the order status
  const isOffer = order.status === 'OFFER';

  const translations = {
    customerDetails: isOffer ? 'Λεπτομέρειες Προσφοράς' : 'Λεπτομέρειες Πελάτη',
    companyDetails: 'Kerino Athens',
    orderDetails: isOffer ? 'Λεπτομέρειες Προσφοράς' : 'Λεπτομέρειες Παραγγελίας',
    item: 'Προϊόν',
    quantity: 'Ποσότητα',
    price: 'Τιμή',
    total: 'Σύνολο',
    vat: 'ΦΠΑ',
    totalVat: 'Συνολικό ΦΠΑ',
    totalItems: isOffer ? 'Συνολικά Προϊόντα (Προσφορά)' : 'Συνολικά Προϊόντα',
    totalVatAmount: isOffer ? 'Συνολικό ΦΠΑ (Προσφορά)' : 'Συνολικό ΦΠΑ',
    totalOrderAmount: isOffer ? 'Συνολικό Κόστος Προσφοράς' : 'Συνολικό Κόστος Παραγγελίας',
    loansHeader: 'Δάνεια',
    servicesHeader: 'Υπηρεσίες',
    printButton: 'Εκτύπωση',
  };

  return (
    <div>
      <div
        id="print-invoice"
        ref={printRef}
        style={{
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          margin: '0 auto',
          width: '80%',
          backgroundColor: '#f9f9f9'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div>
            {/* Customer Information (top left) */}
            <h2 style={{ margin: '0 0 10px' }}>{translations.customerDetails}</h2>
            <p><strong>Όνομα:</strong> {order.name}</p>
            <p><strong>{isOffer ? 'Αριθμός Προσφοράς' : 'Αριθμός Παραγγελίας'}:</strong> {order.id}</p>
          </div>
          <div style={{ textAlign: 'right' }}>
            {/* Company Information (top right) */}
            <h2 style={{ margin: '0 0 10px' }}>{translations.companyDetails}</h2>
            <p>Mixalakopoulou 7, Athens</p>
            <p>GR3423423423423</p>
            <p>Τηλέφωνο: 2819238123</p>
          </div>
        </div>

        {/* Order Details Table */}
        <h3 style={{ borderBottom: '2px solid #000', paddingBottom: '10px' }}>{translations.orderDetails}</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>{translations.item}</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>{translations.quantity}</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>{translations.price}</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>{translations.total}</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>{translations.vat}</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>{translations.totalVat}</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 && (
              <>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px' }}>{product.name}</td>
                    <td style={{ padding: '10px' }}>{product.quantity}</td>
                    <td style={{ padding: '10px' }}>{product.salesprice}</td>
                    <td style={{ padding: '10px' }}>{product.totalPrdAmmount}</td>
                    <td style={{ padding: '10px' }}>{product.vatProducts}</td>
                    <td style={{ padding: '10px' }}>{Number(product.totalPrdAmmount) + Number(product.vatProducts)}</td>
                  </tr>
                ))}
              </>
            )}

            {loans.length > 0 && (
              <>
                <tr>
                  <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'center', padding: '10px', backgroundColor: '#f0f0f0' }}>{translations.loansHeader}</td>
                </tr>
                {loans.map((loan, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px' }}>{loan.name}</td>
                    <td style={{ padding: '10px' }}>{loan.quantity}</td>
                    <td style={{ padding: '10px' }}>{loan.salesprice}</td>
                    <td style={{ padding: '10px' }}></td>
                    <td style={{ padding: '10px' }}></td>
                    <td style={{ padding: '10px' }}></td>
                  </tr>
                ))}
              </>
            )}

            {services.length > 0 && (
              <>
                <tr>
                  <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'center', padding: '10px', backgroundColor: '#f0f0f0' }}>{translations.servicesHeader}</td>
                </tr>
                {services.map((service, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px' }}>{service.name}</td>
                    <td style={{ padding: '10px' }}>{service.quantity}</td>
                    <td style={{ padding: '10px' }}>{service.salesprice}</td>
                    <td style={{ padding: '10px' }}></td>
                    <td style={{ padding: '10px' }}></td>
                    <td style={{ padding: '10px' }}></td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>

        {/* Totals */}
        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <p><strong>{translations.totalItems}:</strong> {totalItems}</p>
          <p><strong>{translations.totalVatAmount}:</strong> {totalVat}</p>
          <p><strong>{translations.totalOrderAmount}:</strong> {totalOrder}</p>
        </div>
      </div>

      {/* Print Button */}
    </div>
  );
}

InvoiceTemplate.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    expected_delivery_date: PropTypes.string,
    products: PropTypes.string.isRequired,
    loans: PropTypes.string,
    services: PropTypes.string,
    totals: PropTypes.string.isRequired,
    shipping: PropTypes.string,
  }).isRequired,
};

export default InvoiceTemplate;
