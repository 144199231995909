import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const SectionTable = ({ tasks, clickedItem }) => {
  const [selectionindex, setselectionindex] = useState(null); // Set initial state as null

  return (
    <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <th style={tableHeaderStyle}>Προϊόν</th>
            <th style={tableHeaderStyle}>Ποσότητα</th>
            <th style={tableHeaderStyle}>Ημερομηνία Αναμονής</th>
            <th style={tableHeaderStyle}>Κατάσταση</th>
            <th style={tableHeaderStyle}>Αριθμός Παραγγελίας</th>
            <th style={tableHeaderStyle}>Όνομα Πελάτη</th>
            <th style={tableHeaderStyle}>Ημερομηνία Αναμενόμενης Παράδοσης</th>
            <th style={tableHeaderStyle}>Selected</th>
          </tr>
        </thead>
        <tbody>
          {tasks &&
            tasks.map((prds, i) => (
              <tr
                key={i}
                onClick={() => {
                  setselectionindex(i); // Set the selected index when a row is clicked
                  clickedItem(prds); // Pass the clicked item up
                }}
                style={{
                  backgroundColor: selectionindex === i ? "#bdf29e" : "#fbfbfb", // Gray when selected, very light gray otherwise
                  cursor: "pointer", // Pointer cursor for better UX
                  color: selectionindex === i ? "black" : "blue", // Change text color when selected
                  transition: "background-color 0.3s ease", // Smooth transition for background color
                }}
              >
                <td style={tableCellStyle}>{prds.name}</td>
                <td style={tableCellStyle}>{prds.quantity}</td>
                <td style={tableCellStyle}>{prds.expectedDate}</td>
                <td style={tableCellStyle}>{prds.status}</td>
                <td style={tableCellStyle}>{prds.order.id}</td>
                <td style={tableCellStyle}>{prds.order.name}</td>
                <td style={tableCellStyle}>{prds.order.expected_delivery_date}</td>
                <td style={tableCellStyle}>
                  {selectionindex === i ? "✔" : ""} {/* Display checkmark if selected */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

// Inline styles for table cells and headers
const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};

const tableHeaderStyle = {
  padding: "10px",
  textAlign: "left",
  borderBottom: "2px solid #ddd",
  backgroundColor: "#f2f2f2",
};

// PropTypes validation for props
SectionTable.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      expectedDate: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      order: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        expected_delivery_date: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  clickedItem: PropTypes.func.isRequired, // Validate that clickedItem is a function
};

export default SectionTable;
