import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const EpsilonRedPieChart = ({ counts }) => {
  const data = [
    { name: "Epsilon Orders", value: counts.totalEpsilon || 0 },
    { name: "Red Orders", value: counts.totalRed || 0 }
  ];

  const COLORS = ["#0088FE", "#FF8042"];

  return (
    <PieChart width={250} height={200}>
      <Pie
        data={data}
        cx={100}
        cy={100}
        outerRadius={60}
        fill="#8884d8"
        dataKey="value"
        label
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

// PropTypes validation
EpsilonRedPieChart.propTypes = {
  counts: PropTypes.shape({
    totalEpsilon: PropTypes.number.isRequired,
    totalRed: PropTypes.number.isRequired
  }).isRequired
};

export default EpsilonRedPieChart;
