import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

// Your Google Maps API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyAvDka_HEWufYVqW_m2KzIfSJEjd9O-gYI';

// The fixed starting and ending address
const START_END_ADDRESS = "Psichari 1, Metamorfosi 144 52, Greece";

const OptimalRouteMap = ({ addresses, onRouteCalculated }) => {
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [totalDistance, setTotalDistance] = useState(''); // Total distance in kilometers
  const [totalDuration, setTotalDuration] = useState(''); // Total time in minutes
  const [routeDetails, setRouteDetails] = useState([]); // List of route details (legs)

  const calculateRoute = (map, maps) => {
    if (addresses.length < 1) {
      console.error('At least one address (besides the starting point) is needed to calculate a route');
      return;
    }

    // Combine fixed starting/ending address with provided addresses
    const allAddresses = [START_END_ADDRESS, ...addresses];
    const directionsService = new maps.DirectionsService();

    if (!directionsRenderer) {
      const renderer = new maps.DirectionsRenderer();
      renderer.setMap(map);
      setDirectionsRenderer(renderer);
    }

    const origin = allAddresses[0]; // Start at Psichari
    const destination = START_END_ADDRESS; // End at Psichari
    const waypoints = allAddresses.slice(1).map(address => ({
      location: address,
      stopover: true,
    }));

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        optimizeWaypoints: true, // Let Google Maps optimize the waypoints order
        travelMode: maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(), // Use current time to consider real-time traffic
          trafficModel: 'bestguess', // 'bestguess', 'pessimistic', or 'optimistic'
        },
      },
      (result, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(result);
          const route = result.routes[0];

          // Calculate total distance and total duration
          let totalDistance = 0;
          let totalDuration = 0;
          const details = route.legs.map((leg) => {
            totalDistance += leg.distance.value; // in meters
            totalDuration += leg.duration_in_traffic ? leg.duration_in_traffic.value : leg.duration.value; // use traffic duration if available
            return {
              start: leg.start_address,
              end: leg.end_address,
              distance: leg.distance.text, // Human-readable format
              duration: leg.duration_in_traffic ? leg.duration_in_traffic.text : leg.duration.text, // Human-readable format with traffic consideration
            };
          });

          // Convert total distance to kilometers and total duration to minutes
          const totalDistanceKm = (totalDistance / 1000).toFixed(2); // Convert meters to kilometers
          const totalDurationMin = Math.floor(totalDuration / 60); // Convert seconds to minutes

          // Update state to display the totals in the component
          setTotalDistance(totalDistanceKm);
          setTotalDuration(totalDurationMin);
          setRouteDetails(details); // Store route details for listing

          // Optionally pass the details to the parent component
          if (onRouteCalculated) {
            onRouteCalculated(details, totalDistanceKm, totalDurationMin);
          }
        } else {
          console.error(`Error calculating directions: ${status}`);
        }
      }
    );
  };

  useEffect(() => {
    if (mapInstance && mapsInstance) {
      calculateRoute(mapInstance, mapsInstance);
    }
  }, [mapInstance, mapsInstance, addresses]);

  return (
    <div>
      {/* Display total distance and time */}
      {totalDistance.length > 0 && 
        <div style={{ marginBottom: '20px' }}>
          <h4>Optimal Route Summary (with Traffic)</h4>
          <p><strong>Total Distance:</strong> {totalDistance} km</p>
          <p><strong>Total Duration:</strong> {totalDuration} minutes</p>
        </div>
      }
            {addresses.length > 1 &&
        <div style={{ height: '300px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
            defaultCenter={{ lat: 37.9838, lng: 23.7275 }} // Default center (Athens)
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              setMapInstance(map);
              setMapsInstance(maps);
            }}
          />
        </div>
      }

      {/* List of route details */}
      {routeDetails.length > 0 &&    
       <div style={{ marginBottom: '20px' }}>
        <h5>Route Details</h5>
        <ul>
          {routeDetails.map((leg, index) => (
            <li key={index}>
              <strong>From:</strong> {leg.start} <br />
              <strong>To:</strong> {leg.end} <br />
              <strong>Distance:</strong> {leg.distance}, <strong>Duration:</strong> {leg.duration}
            </li>
          ))}
        </ul>
      </div>
      }

      {/* Google Map */}

    </div>
  );
};

OptimalRouteMap.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRouteCalculated: PropTypes.func, // Optional callback for sending route details to parent
};

export default OptimalRouteMap;
