import React, { useMemo } from "react";
import PropTypes from 'prop-types';

// Import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';

function SalespersonsTable(props) {
    const data = props.data;

    const columns = useMemo(
        () => [
            {
                Header: 'Αρ.',
                accessor: 'id',
            },
            {
                Header: 'Όνομα',
                accessor: 'name',
            },
            {
                Header: 'Όνομα',
                accessor: 'surname',
            }
        ],
        []
    );

    document.title = "Πίνακες Δεδομένων | kerino - React Admin & Dashboard Template";

    return (
        <TableContainer
            rowClick={"/salesperson-detail"}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={150}
            className="custom-header-css"
        />
    );
}

SalespersonsTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    data: PropTypes.any
};

export default SalespersonsTable;
