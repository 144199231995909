export const printDriver = (content) => {
    // Create an iframe element for printing
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
  
    document.body.appendChild(iframe);
  
    // Access the iframe's document for writing the content
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Print Invoice</title>
          <style>
            @media print {
              body {
                font-family: Arial, sans-serif;
                padding: 20px;
              }
              pre {
                font-size: 14px;
              }
            }
          </style>
        </head>
        <body>
          <pre>${content}</pre>
        </body>
      </html>
    `);
    doc.close();
  
    // Wait for the content to be loaded into the iframe, then trigger the print
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  
    // Clean up by removing the iframe after printing
    document.body.removeChild(iframe);
  };