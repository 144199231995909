import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { CustomerSelect } from "./customersmodal"
import {
  Container,
  Table,
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  CardTitle,
  Alert,
  Input,
  FormGroup,
  Label,
  Button, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import OrderTable from './orderTable'
import LoanorderTable from "./loanorderTable"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCustomers as onGetCustomers } from "store/actions"
import { getProducts, postOrder, getMaterials, getCustOrders, checkOrderStock, postLoan } from "helpers/api_helper"
import { toNumber } from "lodash";
// import UiToast from "components/UiToast";
import { getInvoices as onGetInvoices } from "store/actions"
import MaterialModal from "./materialsum"

const OrderCreate = (props) => {
  document.title = "Create Task | kerino - React Admin & Dashboard Template";
  const dispatch = useDispatch()
  const [productslist, setproductslist] = useState([])
  const [customerorders, setcustomerorders] = useState([])
  const [services, setservices] = useState([])
  const [open, setOpen] = useState(false);
  const [Loanopen, setLoanOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, seterror] = useState("");
  const [startDate, setstartDate] = useState()
  const [customer, setcustomer] = useState({})
  const [names, setnames] = useState("")
  const [customerid, setcustomerid] = useState("")
  const [description, setdescription] = useState("")
  const [products, setproducts] = useState([])
  const [Loanproducts, setLoanproducts] = useState([])
  const [stockstatus, setStockStatus] = useState([])
  const [historyproducts, sethistoryproducts] = useState([])
  const [search, setSearch] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [materialsModalOpen, setMaterialsModalOpen] = useState(false);
  const [orderMaterials, setOrderMaterials] = useState([]);
  const [materialsStocks, setMaterialsStocks] = useState([]);
 


  const resetStates = () => {
    setOpen(false);
    setLoanOpen(false);
    setOpenModal(false);
    seterror("");
    setstartDate(null); // Assuming startDate is a date and null/undefined means reset
    setcustomer({});
    setnames("");
    setcustomerid("");
    setdescription("");
    setproducts([]);
    setLoanproducts([]);
    setStockStatus([]);
    sethistoryproducts([]);
    setSearch('');
    setFilteredProducts([]);
    setMaterialsModalOpen(false);
    setOrderMaterials([]);
    setMaterialsStocks([]);
  };
  const [totals, settotals] = useState({
    totalVat: 0,
    totalProductsAmmount: 0,
    totalItems: 0,
    totalOrder: 0
  })
  const [activeTab, setActiveTab] = useState('tab1'); // Initialize the active tab state

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const { customers } = useSelector(state => ({
    customers: state.customers.customers
  }))




  const alerter = (message) => (
    <Alert color="secondary">
      Error Ocured + {message}
    </Alert>
  )


  const startDateChange = date => {
    setstartDate(date)
  }

  useEffect(() => {
    if (search) {
      // console.log(productslist)
      const searchRegex = new RegExp(search, 'i');
      const filtered = productslist && productslist.filter((cust) =>
        searchRegex.test(cust.name) ||
        searchRegex.test(cust.description)
      )

      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(productslist);
    }
  }, [search, productslist]);




  const checkHistory = (prd) => {
    sethistoryproducts([])
    customerorders.map((order) => {
      const products11 = JSON.parse(order.products)
      const prod = products11.filter(prods => prods.id == prd.id)
      if (prod[0]) {
        const payload = {
          order: order.id,
          customer_id: order.customer_id,
          created: order.created,
          products: prod,
          productprice: prod[0].salesprice,
          productquantity: prod[0].quantity,
          productname: prd.name
        }
        sethistoryproducts(historyproducts => [...historyproducts, payload])
        setOpenModal(true)
      }
    })
  }



  const totalCost = (prd, inde) => {
    const matesum = []
    const steps = JSON.parse(prd.requirements)
    steps.map((step) => {
      step.materials.map((mats) => {
        getMaterials(mats.id).then(res => {
          const mat = mats.quantity * res[0].costprice;
          matesum.push(mat)
          const final = matesum.reduce((a, b) => a + b, 0);
          let newArr = [...products];
          newArr[inde].costprice = final + (final / 100 * prd.costmargin)
          setproducts(newArr);
        })
      })
    })
  }

  useEffect(() => {
    const totalPrdAmmount = sumArray(products, "totalPrdAmmount")
    const totalVat = sumArray(products, "vatProducts")
    const totalQuantity = sumArray(products, "quantity")
    checkOrderStock(products).then(res => {
      const noStock = res.filter((stk) => stk.stockStatus === "STOCK-FAULT")
      if (noStock[0]) {
        setStockStatus(noStock)
      }
      if (!noStock[0]) {
        setStockStatus([])
      }
    })

    const orderTotals = {
      totalVat: Number(totalVat).toFixed(2),
      totalProductsAmmount: totalPrdAmmount,
      totalItems: Number(totalQuantity),
      totalOrder: (Number(totalPrdAmmount) + Number(totalVat)).toFixed(2)
    }
    settotals(orderTotals)
  }, [products])


  useEffect(() => {
    getProducts(null, null).then(res => setproductslist(res))
    products.map((prds, ind) => totalCost(prds, ind))
  }, [open])

  function getCustomerById(id) {
    return customers.find(customer => customer.id === id);
  }


  useEffect(() => {
    customerid && getCustOrders(customerid).then(res => setcustomerorders(res))
    const selcustomer = customerid && getCustomerById(customerid)
    setcustomer(selcustomer)
  }, [customerid])

  useEffect(() => {
    dispatch(onGetCustomers())
  }, [dispatch])


  useEffect(async () => {
    const makis = await checkOrderMaterialsStock(products)
    setMaterialsStocks(makis)
  }, [products])




  const createOffer = () => {


    const customeri = customers.filter((cst) => cst.id === parseInt(customerid))

    const payload = {
      "resource": [
        {
          "name": customeri[0].name,
          "expected_delivery_date": startDate,
          "customer_id": toNumber(customerid),
          "description": description,
          "status": "OFFER",
          "products": JSON.stringify(products),
          "loans": JSON.stringify(Loanproducts),
          "services": JSON.stringify(services),
          "totals": JSON.stringify(totals),
        }
      ],
    }



    postOrder(payload).then(res => {
      //console.log(res.resource)
      if (res && res.error) {
        seterror(res.error)
      }
      if (res && res.resource && res.resource[0]) {
        // console.log(Loanproducts.length)
        props.history.push("/Orders")
        dispatch(onGetInvoices())
      }

    })

  }

  async function checkOrderMaterialsStock(products) {
    return await Promise.all(
      products.map(async (product) => {
        // Get requirements from the product (assuming it's already an object or stringified)
        const requirements = JSON.parse(product.requirements);
  
        // Create a map to store the materials, aggregating them by their IDs
        const materialsMap = new Map();
        const missedStock = [];
  
        for (const requirement of requirements) {
          for (const material of requirement.materials) {
            const materialId = material.id;
  
            // Calculate total required quantity for the product
            const totalRequiredQuantity = parseFloat(product.quantity) * parseFloat(material.quantity);
  
            // Fetch the real stock for the material
            const realStock = await getProducts(materialId, null);
            console.log(realStock)
            const availableStock = parseFloat(realStock[0].stock);
  
            if (materialsMap.has(materialId)) {
              const existingMaterial = materialsMap.get(materialId);
              existingMaterial.quantity += totalRequiredQuantity;
              existingMaterial.sumQuantity += totalRequiredQuantity;
            } else {
              materialsMap.set(materialId, {
                id: material.id,
                name: material.name,
                quantity: totalRequiredQuantity,
                sumQuantity: totalRequiredQuantity,
                stock: availableStock,
                stockStatus: availableStock >= totalRequiredQuantity ? "STOCK-OK" : "NO-STOCK",
              });
            }
  
            // Add to missedStock list if stock is insufficient
            if (availableStock < totalRequiredQuantity) {
              missedStock.push({
                name: material.name,
                stock: availableStock,
                missingStock: totalRequiredQuantity - availableStock,
              });
            }
          }
        }
  
        // Convert map to an array
        const materialsList = Array.from(materialsMap.values());
  
        return {
          productName: product.name,
          quantity: parseFloat(product.quantity),
          materials: materialsList,
          missedStock: missedStock,
        };
      })
    );
  }
  
  const createOrder = (redirect) => {
    const statusCheck = () => {
      if (stockstatus[0]) {
        return "PENDING-STOCK"
      }
      if (!stockstatus[0]) {
        return "STOCK-OK"
      }
    }

    const customeri = customers.filter((cst) => cst.id === parseInt(customerid))

    const payload = {
      "resource": [
        {
          "name": customeri[0].name,
          "delivery_address":customeri[0].address,
          "expected_delivery_date": startDate,
          "customer_id": toNumber(customerid),
          "description": description,
          "status": statusCheck(),
          "products": JSON.stringify(products),
          "loans": JSON.stringify(Loanproducts),
          "services": JSON.stringify(services),
          "totals": JSON.stringify(totals),
        }
      ],
    }



    postOrder(payload).then(res => {
      //console.log(res.resource)
      if (res && res.error) {
        seterror(res.error)
      }
      if (res && res.resource && res.resource[0]) {
        // console.log(Loanproducts.length)
    
        resetStates()
        if(redirect){
         props.history.push("/order-detail/"+res.resource[0].id)
        }
      //  dispatch(onGetInvoices())

      }

    })

  }


  const sumArray = (array, type) => {
    const sumall = array.map(item => item[type] && toNumber(item[type])).reduce((prev, curr) => prev + curr, 0);
    return sumall
  }
  console.log(materialsStocks)

  return (
    <>
      <div className="page-content">
      <MaterialModal 
        isOpen={materialsModalOpen} 
        setisOpen={setMaterialsModalOpen}

        products={materialsStocks} 
      />
        <Container fluid>
          <Modal isOpen={openModal} >
            <ModalHeader tag="h4">
              <Row style={{ marginLeft: 5 }}>
                <Col>Sales History for : {historyproducts && historyproducts[0] && historyproducts[0].productname}</Col>
              </Row>
              {/* <Row> Customer :{historyproducts && historyproducts[0] && historyproducts[0].customer_id}</Row> */}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>Order Id</Col>
                <Col>Quantity</Col>
                <Col>Price Sold </Col>
                <Col>Order Date</Col>
              </Row>
          
              {historyproducts && historyproducts.map((hist, i) => (<Row key={i}>
                <Col>{hist.order}</Col>
                <Col>{hist.productquantity}</Col>
                <Col>{hist.productprice}</Col>
                <Col>{hist.created}</Col>
              </Row>))}
            </ModalBody>
            <Button onClick={() => setOpenModal(false)}>Close</Button>
          </Modal>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup style={{ marginTop: 5 }} className="mb-4" row>

                        <Col lg="2">
                          <CustomerSelect customers={customers} onSelect={(e) => setcustomerid(e)}>
                          </CustomerSelect>
                        </Col>
             

                        <Col lg="10">
                          <Input
                            value={customer && customer.name}
                            type="text"
                            name="select"
                            id="exampleSelect1"
                            className="form-control"
                          >

                          </Input></Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="taskbudget"
                          className="col-form-label col-lg-2"
                        >
                          Description
                        </label>
                        <div className="col-lg-10">
                          <Input
                          style={{height:100}}
                            id="taskbudget"
                            name="taskbudget"
                            type="textarea"
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            placeholder="General information for the order"
                            className="form-control"
                          />
                        </div>
                      </FormGroup>

                    </Col>
                    <Col>
                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Delivery Date
                        </Label>
                        <Col lg="10">
                          <Row>
                            <Col md={12} className="pr-0">
                              <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={startDateChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>

                        <Row>
                          {products.length > 0 &&
                            <CardBody>
                              Summury
                              <h4 className="card-title">Order Items : {products.length} --- Total Quantity : {totals.totalItems} ----- Total Vat Ammount : {totals.totalVat}----- Total Order Ammount : {totals.totalOrder}</h4>
                            </CardBody>
                          }
                        </Row>
                        {materialsStocks && materialsStocks[0] && materialsStocks[0].missedStock && materialsStocks[0].missedStock[0] &&
                        <Row>
                        <Col>
                      <Button style={{backgroundColor:"red"}}onClick={() => setMaterialsModalOpen(true)}>Missing Materials</Button></Col>
                      </Row>}
                        <Row>
                          {stockstatus[0] &&
                            <Card>
                              <CardBody>
                                STOCK STATUS
                                {stockstatus && stockstatus.map((stk, i) => (<div style={{ margin: 10, color: "red" }} key={i}>
                                  -- Product: {stk.product.name}/{stk.product.sku} are only {stk.product.stock} items on stock  but you need {stk.quantity} YOU NEED MORE :{stk.quantity - stk.product.stock}
                                </div>))}
                                {!stockstatus[0] && products.length > 0 && <div style={{ color: "green" }}>ALL products in Stock </div>}
                                {/* <h4 className="card-title">FAILED STOCK  : {stockstatus.length} --- Product : {stockstatus} ----- Total Vat Ammount : {totals.totalVat}----- Total Order Ammount : {totals.totalOrder}</h4> */}
                              </CardBody>
                            </Card>
                          }
                        </Row>
                 
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>


          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'tab1' ? 'active' : ''}
                        onClick={() => toggleTab('tab1')}
                      >
                        Invoiced
                      </NavLink>
                    </NavItem>
                    {Loanproducts && Loanproducts[0] && <NavItem>
                      <NavLink
                        className={activeTab === 'tab2' ? 'active' : ''}
                        onClick={() => toggleTab('tab2')}
                        style={{ backgroundColor: '#F1D9F3' }}
                      >
                        Loan Stock
                      </NavLink>
                    </NavItem>}

                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="tab1">
                      <OrderTable
                        checkHistory={checkHistory}
                        setproducts={setproducts}
                        setLoanproducts={setLoanproducts}
                        names={setnames}
                        getProducts={getProducts}
                        products={products}
                        Loanproducts={Loanproducts}
                      />
                    </TabPane>

                    <TabPane tabId="tab2">
                      <LoanorderTable
                        checkHistory={checkHistory}
                        setproducts={setLoanproducts}
                        names={setnames}
                        products={Loanproducts}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <Row className="justify-content-start">
                  {activeTab === 'tab1' && <Col lg="2">
                    <Button onClick={() => setOpen(true)} type="submit" color="primary">
                      Add Products
                    </Button>
                  </Col>}

                  {/* {activeTab === 'tab2' && <Col lg="2">
                    <Button onClick={() => setLoanOpen(true)} type="submit" color="primary">
                      Add Loan Products
                    </Button>
                  </Col>} */}


                  <Col lg="2">
                    <Button disabled={customerid.length < 1 || products.length < 1} onClick={() => createOrder()} color="primary">
                      Create Order
                    </Button>
          
                  </Col>

                  <Col lg="2">
                    <Button disabled={customerid.length < 1 || products.length < 1} onClick={() => createOrder(true)} color="primary">
                      Create and GO ! 
                    </Button>
          
                  </Col>
                  <Col>
                  <Button disabled={customerid.length < 1 || products.length < 1} onClick={() => createOffer()} color="primary">
                      Create Offer
                    </Button>
                    </Col>
                
    



                    
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>

        {activeTab === 'tab1' && <ReactDrawer
          open={open}
          position={'left'}
          onClose={() => setOpen(false)}
          style={{ width: 'calc(100% + 20px)' }}
        >
          <Row style={{ margin: 10 }}>
            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search"></Input>
          </Row>
          <div style={{ margin: 10, maxHeight: '90%', overflowY: 'auto' }}>
    
            {filteredProducts && filteredProducts.map((prd, i) => (
              <Row
                key={i}
                onClick={() => {

                  Object.assign(prd, { quantity: toNumber(0) })
                  setproducts(products => [...products, prd])
                  setOpen(false)
                }}
                onMouseOver={() => {
                  document.getElementById(`row${i}`).style.backgroundColor = "#cccccc";
                }}
                onMouseLeave={() => {
                  document.getElementById(`row${i}`).style.backgroundColor = i % 2 === 0 ? '#DBDAF8' : '#fff';
                }}
                style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: i % 2 === 0 ? '#EBEBF6' : '#fff', borderTop: '3px solid #ddd' }}
                id={`row${i}`}
              >
                <Col lg="6">{prd.name}</Col>
                <Col lg="3">{prd.salesprice}</Col>
                <Col lg="2">{prd.stock}</Col>
                
              </Row>
            ))}

          </div>
        </ReactDrawer>}

        {activeTab === 'tab2' && <ReactDrawer
          open={Loanopen}
          position={'left'}
          onClose={() => setLoanOpen(false)}
          style={{ width: 'calc(100% + 20px)' }}
        >
          <Row style={{ margin: 10 }}>
            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search"></Input>
          </Row>
          <div style={{ margin: 10, maxHeight: '90%', overflowY: 'auto' }}>

            {filteredProducts && filteredProducts.map((prd, i) => (
              <Row
                key={i}
                onClick={() => {

                  Object.assign(prd, { quantity: toNumber(0) })
                  setLoanproducts(Loanproducts => [...Loanproducts, prd])
                  setLoanOpen(false)
                }}
                onMouseOver={() => {
                  document.getElementById(`row${i}`).style.backgroundColor = "#cccccc";
                }}
                onMouseLeave={() => {
                  document.getElementById(`row${i}`).style.backgroundColor = i % 2 === 0 ? '#DBDAF8' : '#fff';
                }}
                style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: i % 2 === 0 ? '#EBEBF6' : '#fff', borderTop: '3px solid #ddd' }}
                id={`row${i}`}
              >
                <Col lg="6">{prd.name}</Col>
                <Col lg="3">{prd.salesprice}</Col>
                <Col lg="2">{prd.stock}</Col>
              </Row>
            ))}

          </div>
        </ReactDrawer>}

        {error && alerter()}
      </div>
    </>
  )
}


OrderCreate.propTypes = {
  customers: PropTypes.array,
  onGetInvoices: PropTypes.func,
  history: PropTypes.object,
  names: PropTypes.string,

}

export default withRouter(OrderCreate)
