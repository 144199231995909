import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    FormGroup,
    Form,

} from "reactstrap";

import PropTypes from "prop-types"
import PLYSIMO from "./PLYSIMO";


//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";
import { postProduct, getTypes, getMaterials, getTasks } from "../../helpers/api_helper";

// import { getTasks as onGetTasks } from "../../store/tasks/actions"
import { getProducts, } from "helpers/api_helper"
import TRIMA from "./TRIMA";
import MyDrawer from "./drawer";
import ReactDrawer from 'react-drawer';


const Section = (props) => {
 

    //meta title
    document.title = "Profile | kerino - React Admin & Dashboard Template";
    const [section, setsection] = useState("");
    const [types, settypes] = useState([]);
    const [materialsList, setMaterialsList] = useState([]);
    const [sectiontasks, setsectiontasks] = useState([]);
    const [type, settype] = useState(props.location.state ? props.location.state : "")
    const dispatch = useDispatch()

    const [tasks, setTasks] = useState([])
    const [filteredMaterials, setFilteredMaterials] = useState([])
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedReplace, setselectedReplace] = useState({});
    const [selectedReplaceindex, setselectedReplaceindex] = useState("");



    useEffect(() => {
        getTasks().then(res => setTasks(res))
    }, [])

    useEffect(() => {

        if(props.location.state) {
            settype(props.location.state)
            refreshTasks()
        }
    }, [props.location.state])

    const refreshTasks = (selectedTask) => {
        setsectiontasks([])
        getTasks().then(res => {
            setTasks(res)
            const filtered = res.filter((tsk) => tsk.type == type)
            setsectiontasks(filtered)
        })

    }

    useEffect(() => {

        getTypes().then(res => settypes(res))
        getMaterials().then(res => setMaterialsList(res))
    }, [])

    useEffect(() => {
        if (search) {
            const searchRegex = new RegExp(search, 'i');
            const filtered = materialsList && materialsList.filter((cust) =>
                searchRegex.test(cust.name) ||
                searchRegex.test(cust.description)
            );
            setFilteredMaterials(filtered);
        } else {
            const filtered = materialsList && materialsList.filter((cust) => cust.stock > 0 && cust.usageType !== "COMPLETE-PRODUCT");
            setFilteredMaterials(filtered);
        }
    }, [search, materialsList]);


    // function filterPayload(payload, type) {
    //     let filteredPayload = [];
    //     payload.forEach((item) => {
    //         if (item.type === type && item.seq === 1) {
    //             filteredPayload.push(item);
    //         } else if (item.type === type && item.seq > 1 && (item.status === "PENDING" || item.status === "STOCK-RECEIVED")) {
    //             const previousTask = payload.find(
    //                 (prevItem) => prevItem.orderref === item.orderref && prevItem.seq === item.seq - 1 && prevItem.status === "FINISHED"
    //             );
    //             if (previousTask) {
    //                 filteredPayload.push(item);
    //             }
    //         }
    //     });
    //     return filteredPayload;
    // }


    useEffect(() => {
refreshTasks()
    }, [type])


    const getLStock = async (id) => {
        const stock = await getProducts(id).then(res => res[0])
        //   console.log(stock.stock)
        return await stock.stock
    }
    // console.log(JSON.stringify(sectiontasks))
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>

                        <CardBody>
                            <FormGroup className="mb-4" row>
                                <label
                                    htmlFor="taskbudget"
                                    className="col-form-label col-lg-2"
                                >
                                    Select Section
                                </label>
                                <div className="col-lg-10">
                                    <Input value={type} onChange={(e) => settype(e.target.value)} type="select" name="select" id="exampleSelect">
                                        {types && types.map((tip, i) => <option value={tip.name} key={i}>{tip.name}</option>)}
                                    </Input>
                                </div>
                            </FormGroup>
                        </CardBody>
                    </Card>
                    {type === "ΠΛΥΝΤΗΡΙΟ" &&
                        <PLYSIMO type={type} refresh={(sel) => refreshTasks(sel)} getLStock={getLStock} sectiontasks={sectiontasks} allTasks={tasks}></PLYSIMO>
                    }

                    {type != "ΠΛΥΝΤΗΡΙΟ" &&
                        <TRIMA
                            selectedReplace={selectedReplace}
                            openDrawer={open} setopenDrawer={(e, matr, reqQuant) => {
                                setOpen(e)
                                setselectedReplaceindex(matr)
                            }}
                            refreshTasks={refreshTasks}
                            type={type}
                            refresh={(sel) => {
                                refreshTasks(sel)
                            }
                            }
                            setsectionTasks={setsectiontasks}
                            getLStock={getLStock}
                            sectiontasks={sectiontasks}
                            allTasks={tasks}
                        // setallTasks={(e) => setTasks(e)}
                        >

                        </TRIMA>
                    }


                </Container>
                <MyDrawer
                    open={open}
                    position={'right'}
                    onClose={() => setOpen(false)}
                    style={{ width: 'calc(100% + 20px)' }}
                    filteredMaterials={filteredMaterials}
                    selectedStep={(e) => setselectedReplace({ toreplace: selectedReplaceindex, new: e })}
                    setSearch={(e) => setSearch(e)}
                />
            </div>
        </React.Fragment>
    );
};
Section.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
}
export default withRouter(Section);
