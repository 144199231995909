import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { postProduct, getProducts } from "helpers/api_helper";

const ProductDetail = (props) => {
  const {
    match: { params },
  } = props;

  const [product, setProduct] = useState();
  const [requirements, setRequirements] = useState([]);

  useEffect(() => {
    if (params && params.id) {
      getProducts(params.id).then((res) => {
        setProduct(res[0]);
        setRequirements(JSON.parse(res[0].requirements));
      });
    }
  }, [params]);

  const handleCloneProduct = () => {
    const payload = {
      "resource": [
        {
          "name": `${product.name} (Copy)`,
          "description": product.description,
          "b2bsalesprice": product.b2bsalesprice,
          "usageType": product.usageType,
          "sku": `${product.sku}-copy`,
          "vat": product.vat,
          "salesprice": product.salesprice,
          "vatClass": product.vatClass,
          "unit": product.unit,
          "category": product.category,
          "requirements": product.requirements,
        }
      ]
    };

    postProduct(payload).then((res) => {
      if (res && res.error) {
        console.log(res.error);
      }
      if (res && res.resource && res.resource[0]) {
        props.history.push("/product-overview/" + res.resource[0].id);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <Card>
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1 align-self-center">
                                <div className="text-muted">
                                  <h5>Name: {product.name}</h5>
                                  <h5>Sales Price: {product.salesprice}</h5>
                                  <p className="mb-0">B2B Price: {product.b2bsalesprice}</p>
                                  <p className="mb-0">Sales Price: {product.salesprice}</p>
                                  <p className="mb-0">Unit: {product.unit}</p>
                                  <p className="mb-0">Vat: {product.vat}%</p>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Button onClick={() => props.history.push('/product-update', { product })}>
                          Edit Product
                        </Button>
                        <Button onClick={handleCloneProduct} className="ms-2" color="primary">
                          Clone Product
                        </Button>
                      </Col>
                      <Col xl="6">
                        <Card>
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1 align-self-center">
                                <div className="text-muted">
                                  <h5>SKU: {product.sku}</h5>
                                  <h5>STOCK: {product.stock}</h5>
                                  <p className="mb-0">Production Cost: 2222</p>
                                  <p className="mb-0">Production Hours: 2222</p>
                                </div>
                              </div>
                              <div className="ms-3">
                                <img
                                  src={avatar}
                                  alt=""
                                  className="avatar-md rounded-circle img-thumbnail"
                                />
                              </div>
                            </div>
                            {product && product.productPhotos && JSON.parse(product.productPhotos).map((img, i) => (
                              <div key={i} style={{ position: 'relative', display: 'inline-block', margin: 10 }}>
                                <img src={img} alt="webcam preview" style={{ width: '150px', height: '150px' }} />
                              </div>
                            ))}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

ProductDetail.propTypes = {
  match: PropTypes.any,
  history: PropTypes.object,
};

export default ProductDetail;
