import React, { useEffect, useState } from "react";
import { Col, Container, Row,Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";

// redux
import { useSelector, useDispatch } from "react-redux";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Card invoice
import CardInvoice from "./card-invoice";
import { getInvoices as onGetInvoices } from "store/actions";
import OrdersTable from "./ordersTable";
import ShippingTable from "./shippingtable";
import { getOrdersbystatus } from "helpers/api_helper";

const ShippingList = (props) => {
  // meta title
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for spinner

  useEffect(() => {

    getOrdersbystatus("INVOICED").then((res) => {
      setFilteredOrders(res)
      setLoading(false)
    }
    );
  }, []);

  return (
    <React.Fragment>
      <div className="page-content" >
        {loading &&
          // Show the spinner while customers are loading
          <div className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
            <p>Loading Shipping Orders...</p>
          </div>
        }
        {!loading &&
          <ShippingTable
            onUpdateSelectedOrders={() => {
              setFilteredOrders([]);
              getOrdersbystatus("INVOICED").then((res) => setFilteredOrders(res));
            }}
            orders={filteredOrders}
          ></ShippingTable>}

        {/* Render Breadcrumbs */}


      </div>
    </React.Fragment>
  );
};

ShippingList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
};

export default withRouter(ShippingList);
