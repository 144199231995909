import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getProducts } from "helpers/api_helper"; // Assuming this is your API helper
import { useDispatch } from "react-redux";
import DatatableTables from "./datatable";

const ProductsList = (props) => {
  document.title = "Invoice List | customers - React Admin & Dashboard Template";
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // To track loading state

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Start loading
      try {
        const fetchedProducts = await getProducts(); // Fetch products from API
        setProducts(fetchedProducts);
      } catch (error) {
        // If fetching fails, redirect to /login
        localStorage.removeItem("authUser");
        props.history.push("/login");
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchProducts();
  }, [props.history]);

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <div className="loader">Loading...</div> // Loader while fetching
        ) : (
          <>
            <div>
              {/* Additional buttons or actions can go here */}
              {/* Example Button: */}
              {/* <Button color="primary" onClick={updateProductsCostPrice}>
                Update Products Live Costs
              </Button> */}
            </div>
            <DatatableTables products={products} />
            <Row>
              <Col xs="12">
                <div className="text-center my-3"></div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

// PropTypes for ProductsList component
ProductsList.propTypes = {
  history: PropTypes.object.isRequired, // Required prop for history object
  invoices: PropTypes.array, // Optional array of invoices, if applicable
  onGetInvoices: PropTypes.func, // Optional function to get invoices
};

export default withRouter(ProductsList);
