import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DatatableTables from "./datatable";
import { useSelector, useDispatch } from "react-redux";
import { getCustomers as onGetCustomers } from "store/actions";

const CustomersList = (props) => {
  document.title = "Customers List | customers - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { customers } = useSelector((state) => ({
    customers: state.customers.customers,
  }));

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        await dispatch(onGetCustomers());
        setLoading(false);
      } catch (error) {
        // If fetching fails, redirect to /login
        localStorage.removeItem("authUser");
        props.history.push("/login");
      }
    };

    fetchCustomers();
  }, [dispatch, props.history]);

  useEffect(() => {
    if (customers === 400 || customers === 401 || customers === 403) {
      localStorage.removeItem("authUser");
      props.history.push("/login");
    }
  }, [customers, props.history]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    const parsedAuthUser = authUser ? JSON.parse(authUser) : null;

    if (parsedAuthUser) {
      const redirectMapping = {
        "estiash": "/single/ΕΣΤΙΑΣΗ",
        "kormakia": "/single/ΚΟΡΜΑΚΙΑ",
        "fitilia": "/single/ΦΥΤΙΛΙΑ",
        "pressa": "/single/ΠΡΕΣΣΑ",
        "plyntirio": "/single/ΠΛΥΝΤΗΡΙΟ",
        "trima": "/single/ΤΡΥΜΑ",
        "tripsimo": "/single/ΤΡΙΨΙΜΟ",
        "benetiko": "/single/ΒΕΝΕΤΙΚΟ",
        "bafh": "/single/ΒΑΦΗ",
        "xyta": "/single/ΧΥΤΑ",
        "extruder": "/single/EXTRUDER",
      };

      const redirectPath = redirectMapping[parsedAuthUser.first_name];
      if (redirectPath) {
        props.history.push(redirectPath);
      }
    }
  }, [props.history]);

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <div className="loader">Loading...</div>
        ) : (
          customers && customers[0] && <DatatableTables products={customers} />
        )}
        <Row>
          <Col xs="12">
            <div className="text-center my-3"></div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

CustomersList.propTypes = {
  customers: PropTypes.array,
  onGetInvoices: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(CustomersList);
