import React, { useState ,useEffect} from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import { CatCustomer, CatUnits, CatVat } from "./categoryInput"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postCustomer, getSalesPersons } from "../../helpers/api_helper"
import { TagsCustomers } from "./tagscategories"
import VATModal from "./vatModal"
import data from './DataExport-Customers.json';

import MapModal from "./mapAddress"
const CustomerCreate = (props) => {

  document.title = "Δημιουργία Πελάτη";

  const [startDate, setstartDate] = useState(new Date())
  const [name, setname] = useState("")
  const [label, setlabel] = useState("")
  const [address, setaddress] = useState("")
  const [phone, setphone] = useState("")
  const [discount, setdiscount] = useState("")
  const [representor, setrepresentor] = useState("")
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [vattype, setvattype] = useState("");
  const [vat, setvat] = useState("");
  const [social, setsocial] = useState("");
  const [profesion, setprofesion] = useState("");
  const [email, setemail] = useState("");
  const [salespersons, setsalesperson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [salespersonlist, setsalespersonslist] = useState([]);
  const [salesperson, setSalesperson] = useState('');
  const [taxoffice, setTaxoffice] = useState('');
  const startDateChange = date => {
    setstartDate(date)
  }



  useEffect(() => {
    getSalesPersons().then(res => setsalespersonslist(res));

  }, []);

  const setCompanyDetails = (compdetails) => {
    if (compdetails) {
      setvat(compdetails.vat)
      setname(compdetails.name)
      setaddress(compdetails.address)
      setcountry(compdetails.country)
    }
  }

  const createCustomer = () => {
    const payload = {
      "resource": [
        {
          "name": name,
          "label": label,
          "taxOffice":taxoffice,
          "address": address,
          "phone": phone,
          "startdate": startDate,
          "representor": representor,
          "discount": discount,
          "category": category,
          "vatType": vattype,
          "vat": vat,
          "social": social,
          "profession": profesion,
          "email": email,
          "tags": JSON.stringify(tags),
          "salespersons": salesperson,
          "city": city,
          "country": country
        }
      ],
    }

    postCustomer(payload).then(res => {
      if (res && res.error) {
        //console.log(res)
      }
      if (res && res.resource) {
        props.history.push("/customers")
      }
    })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Πελάτες" breadcrumbItem="Customer Create" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Δημιουργία Πελάτη</CardTitle>

                  <VATModal
                    ValidData={setCompanyDetails} ></VATModal>
                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="Customername"
                          className="col-form-label col-lg-2"
                        >
                          Όνομα
                        </Label>
                        <Col lg="10">
                          <Input
                            id="Customername"
                            name="name"
                            type="text"
                            className="form-control"
                            onChange={(e) => setlabel(e.target.value)}
                            placeholder="Εισάγετε το Όνομα Πελάτη..."
                            value={label}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="Customername"
                          className="col-form-label col-lg-2"
                        >
                          Εταιρεία
                        </Label>
                        <Col lg="10">
                          <Input
                            id="Customername"
                            name="name"
                            type="text"
                            className="form-control"
                            onChange={(e) => setname(e.target.value)}
                            placeholder="Εισάγετε το Όνομα Πελάτη..."
                            value={name}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Col lg="2">
                          <Label>
                            Tax Office- DOY
                          </Label>
                        </Col>

                        <Col lg="6">
                          <Input
                            id="company name"
                            name="address"
                            type="text"
                            value={taxoffice || ''}
                            className="form-control"
                            onChange={(e) => setTaxoffice(e.target.value)}
                            placeholder="Εισάγετε τη Διεύθυνση Πελάτη..."
                          />
                        </Col>
                        <Col lg="4">
                          <MapModal address={address} onAddressSelect={(a) => setaddress(a)}></MapModal>
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Col lg="2">
                          <Label>
                            Διεύθυνση
                          </Label>
                        </Col>

                        <Col lg="6">
                          <Input
                            id="company name"
                            name="address"
                            type="text"
                            value={address || ''}
                            className="form-control"
                            onChange={(e) => setaddress(e.target.value)}
                            placeholder="Εισάγετε τη Διεύθυνση Πελάτη..."
                          />
                        </Col>
                        <Col lg="4">
                          <MapModal address={address} onAddressSelect={(a) => setaddress(a)}></MapModal>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Τηλέφωνο
                        </Label>
                        <Col lg="10">
                          <Input
                            id="phone"
                            name="phone"
                            value={phone}
                            type="text"
                            className="form-control"
                            onChange={(e) => setphone(e.target.value)}
                            placeholder="Εισάγετε το Τηλέφωνο..."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Κατηγορία
                        </label>
                        <div className="col-lg-10">
                          <CatCustomer category={category} setCategory={setCategory}></CatCustomer>
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Ετικέτες Κατηγορίας
                        </label>
                        <div className="col-lg-10">
                          <TagsCustomers tags={tags} setTags={setTags}></TagsCustomers>
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Ημερομηνία Δημιουργίας
                        </Label>
                        <Col lg="10">
                          <Row>
                            <Col md={6} className="pr-0">
                              <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={startDateChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Επάγγελμα
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="Customerbudget"
                            name="Customerbudget"
                            type="text"
                            placeholder="Εκπτωτικός Πελάτης"
                            className="form-control"
                            value={profesion}
                            onChange={(e) => setprofesion(e.target.value)}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Κοινωνικά
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="Customerbudget"
                            name="Customerbudget"
                            type="text"
                            placeholder="Εκπτωτικός Πελάτης"
                            className="form-control"
                            value={social}
                            onChange={(e) => setsocial(e.target.value)}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Email
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="Customerbudget"
                            name="Customerbudget"
                            type="text"
                            placeholder="Εκπτωτικός Πελάτης"
                            className="form-control"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          ΑΦΜ
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="vat"
                            name="Vat"
                            type="text"
                            placeholder="Εκπτωτικός Πελάτης"
                            className="form-control"
                            value={vat}
                            onChange={(e) => setvat(e.target.value)}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Τύπος ΑΦΜ
                        </label>
                        <div className="col-lg-10">
                          <CatVat catVat={vattype} setCatVat={setvattype}></CatVat>
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="Customerbudget"
                          className="col-form-label col-lg-2"
                        >
                          Κύρια Έκπτωση
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="Customerbudget"
                            name="Customerbudget"
                            type="text"
                            placeholder="Κύρια Έκπτωση Πελάτη"
                            className="form-control"
                            value={discount}
                            onChange={(e) => setdiscount(e.target.value)}
                          />
                        </div>
                      </FormGroup>
           

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="salespersonSelect"
                          className="col-form-label col-lg-2"
                        >
                          Πωλητής
                        </label>
                        <Col>
                          <select
                            id="salespersonSelect"
                            value={salesperson}
                            onChange={(e) => setSalesperson(e.target.value)}
                            className="form-control"
                          >
                            <option value="" disabled>
                              Select a salesperson
                            </option>
                            {salespersonlist && salespersonlist.map((person, i) => (
                              <option key={i} value={person.id}>
                                {person.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      
                      </FormGroup>
                    </div>
                  </div>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button disabled={name.length < 4} onClick={() => createCustomer()} type="submit" color="primary">
                        Δημιουργία Πελάτη
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
CustomerCreate.propTypes = {
  history: PropTypes.object,
}
export default CustomerCreate
