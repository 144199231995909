import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postSalesperson } from "../../helpers/api_helper";

const SalespersonCreate = (props) => {
  document.title = "Δημιουργία Πωλητή";

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [firstDiscount, setFirstDiscount] = useState("");
  const [normalDiscount, setNormalDiscount] = useState("");

  const createSalesperson = () => {
    const discounts = {
        firstDiscount: firstDiscount,
    normalDiscount: normalDiscount,
  }
    const payload = {
      resource: [
        {
          name: name,
          surname: surname,
         discounts: JSON.stringify(discounts)
        },
      ],
    };

    postSalesperson(payload).then((res) => {
      if (res && res.error) {
        // Handle error (e.g., show an error message)
      }
      if (res && res.resource) {
        props.history.push("/");
      }
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Πωλητές" breadcrumbItem="Create Salesperson" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label htmlFor="SalespersonName" className="col-form-label col-lg-2">
                            Όνομα
                          </Label>
                          <Col lg="10">
                            <Input
                              id="SalespersonName"
                              name="name"
                              type="text"
                              className="form-control"
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Εισάγετε το όνομα..."
                              value={name}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label htmlFor="SalespersonSurname" className="col-form-label col-lg-2">
                            Επώνυμο
                          </Label>
                          <Col lg="10">
                            <Input
                              id="SalespersonSurname"
                              name="surname"
                              type="text"
                              className="form-control"
                              onChange={(e) => setSurname(e.target.value)}
                              placeholder="Εισάγετε το επώνυμο..."
                              value={surname}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label htmlFor="FirstDiscount" className="col-form-label col-lg-2">
                            Πρώτη Έκπτωση
                          </Label>
                          <Col lg="10">
                            <Input
                              id="FirstDiscount"
                              name="firstDiscount"
                              type="number"
                              className="form-control"
                              onChange={(e) => setFirstDiscount(e.target.value)}
                              placeholder="Εισάγετε την πρώτη έκπτωση..."
                              value={firstDiscount}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label htmlFor="NormalDiscount" className="col-form-label col-lg-2">
                            Κανονική Έκπτωση
                          </Label>
                          <Col lg="10">
                            <Input
                              id="NormalDiscount"
                              name="normalDiscount"
                              type="number"
                              className="form-control"
                              onChange={(e) => setNormalDiscount(e.target.value)}
                              placeholder="Εισάγετε την κανονική έκπτωση..."
                              value={normalDiscount}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                  </form>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button
                        disabled={!name || !surname}
                        onClick={createSalesperson}
                        type="submit"
                        color="primary"
                      >
                        Δημιουργία Πωλητή
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

SalespersonCreate.propTypes = {
  history: PropTypes.object,
};

export default SalespersonCreate;
