import React, { useEffect,useState } from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import SalespersonsTable from "./salespersonstable"


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getSalesPersons, getSalespersons } from "helpers/api_helper"
const SalesPersonList = props => {
   //meta title
   //console.log(props)
   document.title="Custoemers List | customers - React Admin & Dashboard Template";

   const [salespersons, setsalespersons] = useState([])

   useEffect(() => {
getSalesPersons().then(res =>setsalespersons(res));

  }, []);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="customers" breadcrumbItem="salespersons List" />
          <Row>
       
              <SalespersonsTable data={salespersons} />

          </Row>
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                {/* <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SalesPersonList.propTypes = {
  customers: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(SalesPersonList)
