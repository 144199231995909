import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Collapse,
  Table
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { patchSalesPerson,getSalesPersons,getSalesPersonsCustomers } from "helpers/api_helper"; // Adjust this path to your actual helpers
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg"; // Placeholder image, adjust as needed
import CustomerList from "./salesmancustomers";
const SalespersonDetail = (props) => {
  document.title = "Προφίλ Παρόχου | React Admin & Dashboard Template";
  
  const { id } = useParams(); // Λήψη ID παρόχου από URL
  const history = useHistory();
  const [salespersonCustomers, setsalespersonCustomers] = useState([]);
  const [SalesPerson, setSalesPerson] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  
  useEffect(() => {
    if (id) {
        getSalesPersons(id).then(res => {
        if (res && res[0]) {
          const SalesPersonData = res[0];
          setSalesPerson(SalesPersonData);
          setEmail(SalesPersonData.email || '');
          setStartDate(new Date(SalesPersonData.startDate || Date.now()));
        } else {
          setError("Ο πάροχος δεν βρέθηκε");
        }
      }).catch(err => {
        setError("Σφάλμα κατά τη λήψη των στοιχείων του παρόχου");
      });
      getSalesPersonsCustomers(id).then(res => {
        if (res && res[0]) {
            const customers= res;
            setsalespersonCustomers(customers)

          }

      } )
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSalesPerson(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdate = () => {
    const payload = {
      resource: [SalesPerson]
    };
    patchSalesPerson(payload).then(res => {
      if (res && res.error) {
        setError("Σφάλμα κατά την ενημέρωση του παρόχου");
      } else {
        history.push("/salespersons");
      }
    }).catch(err => {
      setError("Σφάλμα κατά την ενημέρωση του παρόχου");
    });
  };

  const handleBack = () => {
    history.push("/salespersons");
  };
console.log(salespersonCustomers)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Πάροχος" breadcrumbItem="SalesPerson Detail" />
          {error && <Alert color="danger">{error}</Alert>}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {SalesPerson && (
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <div className="ms-3">
                            <img
                              src={avatar}
                              alt="Εικόνα Polith"
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h5>{SalesPerson.name}</h5>
                              <p className="mb-1">{email}</p>
                              <p className="mb-0">ID: #{SalesPerson.id}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <Row>
              <Col>
                <h4 className="card-title mb-4">Λεπτομέρειες POlith</h4>
              </Col>
              <Col>
                <Button onClick={() => setIsEditing(!isEditing)}>
                  {isEditing ? "Ακύρωση" : "Επεξεργασία"}
                </Button>
              </Col>
            </Row>
          </div>

          <Card>
            <Collapse isOpen={isEditing}>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdate();
                  }}
                >
                  <div className="form-group">
                    <Label className="form-label">Όνομα</Label>
                    <Input
                      name="name"
                      className="form-control"
                      placeholder="Εισάγετε το όνομα"
                      type="text"
                      value={SalesPerson ? SalesPerson.name : ''}
                      onChange={handleInputChange}
                    />
                    <Label className="form-label">Epitheto</Label>
                    <Input
                      name="address"
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={SalesPerson ? SalesPerson.address : ''}
                      onChange={handleInputChange}
                    />
           
                  </div>
                  <div className="text-center mt-4">
                    <Button onClick={() => handleUpdate()} color="primary">
                      Ενημέρωση Polith
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Collapse>
          </Card>

          {SalesPerson && SalesPerson.payments && SalesPerson.payments.length > 0 && (
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Πληρωμές</h4>
                <Table className="table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>Ημερομηνία</th>
                      <th>Ποσό</th>
                      <th>Κατηγορία</th>
                      <th>Σχόλια</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SalesPerson.payments.map((payment, index) => (
                      <tr key={index}>
                        <td>{payment.date}</td>
                        <td>{payment.ammount}</td>
                        <td>{payment.bound}</td>
                        <td>{payment.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          )}
          
          {/* You can add additional sections or cards here if needed */}
          <CustomerList customers={salespersonCustomers} salesPerson={SalesPerson}></CustomerList>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

SalespersonDetail.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default SalespersonDetail;
