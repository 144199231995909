import axios from "axios"
import { useHistory } from "react-router-dom";
import accessToken from "./jwt-token-access/accessToken"
import { SALESPERSONS,PAYMENTS,GET_CODES,POST_LOGIN, DFKEY, GET_ORDERS, GET_TASKS, GET_CUSTOMERS, CREATE_CUSTOMER, GET_PRODUCTS, CREATE_PRODUCT, CREATE_ORDER, CREATE_TASK, GET_TYPES,GET_PROVIDERS,INVOICES,CREATE_LOAN} from "./df_urls"
const sessionToken = JSON.parse(localStorage.getItem('authUser'));
// //pass new generated access token here
const token = accessToken
//console.log(sessionToken)

//apply base url for axios
const API_URL = "http://localhost/api/v2/kerino/"
const axiosApi = axios.create({
  baseURL: API_URL,

})

const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Dreamfactory-Api-Key": DFKEY,
    "X-Dreamfactory-Session-Token": sessionToken ? sessionToken.session_token : ""
  }
}

export async function deleteProduct(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "DELETE"
  }
  return await fetch(GET_PRODUCTS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function getSalesPersonsCustomers(id) {
  if (id) {
    return await fetch(GET_CUSTOMERS + "?filter=salespersons=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(GET_CUSTOMERS, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
}


export async function postSalesperson(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(SALESPERSONS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function getSalesPersons(id) {
  if (id) {
    return await fetch(SALESPERSONS + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(SALESPERSONS, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
}

export async function patchSalesPerson(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(SALESPERSONS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function getPayments(id) {
  if (id) {
    return await fetch(PAYMENTS + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(PAYMENTS, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
}
export async function getLoans(id) {
  if (id) {
    return await fetch(CREATE_LOAN + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(CREATE_LOAN, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
}

export const checkOrderStock = async (products) => {
  const status = [];
  const requests = products.map((prd, i) =>
    getProducts(prd.id).then(res => {

      if ((Number(res[0].stock) - Number(prd.quantity)) >= 0) {
        status.push({ stockStatus: "STOCK-OK", quantity: prd.quantity, product: res[0] })
        return "STOCK-OK"
      }
      if ((Number(res[0].stock) - Number(prd.quantity)) < 0) {
        console.log(Number(res[0].stock))
        status.push({ stockStatus: "STOCK-FAULT", quantity: Number(prd.quantity), product: res[0] })
        return "STOCK-FAULT"
      }
    })
  )
  return Promise.all(requests).then(() => {
    return status
  });
}

export async function patchMaterial(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(GET_PRODUCTS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function postInvoice(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(INVOICES, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function getInvoices(id) {
  if (id) {
    return await fetch(INVOICES + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(INVOICES, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
}


export async function getProviders(id) {
  if (id) {
    return await fetch(GET_PROVIDERS + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(GET_PROVIDERS, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
}

export async function getCodes() {
 
    return await fetch(GET_CODES, headers)
      .then(response => response.json())
      .then(data => data.resource);
  
}


export async function calccost(prd) {
  const requirements = JSON.parse(prd.requirements)
  let matesum = []
  return await requirements.map((step, i) => {
    step.materials.map((mats) => {
      getMaterials(mats.id).then(res => {
        const mat = mats.quantity * res[0].costprice;
        matesum.push(mat)
  
        return matesum.reduce((a, b) => a + b, 0)
      })
    })

    if (requirements.length === i + 1) {
      return matesum.reduce((a, b) => a + b, 0)
    }
  })
}



export async function totalCost(steps) {
  const products = await getProducts(null, "MATERIAL");

  for (const prod of products) {
    const matesum = [];
    const prod_requirements = JSON.parse(prod.requirements);
    for (const reqs of prod_requirements) {
      for (const mats of reqs.materials) {
        const res = await getMaterials(mats.id);
       // console.log(mats.quantity)
        const mat = mats.quantity * res[0].costprice;
        // const mat = {material: mats.name, matid:res[0].id ,liveCost: res[0].costprice}
      //  console.log(prod.name)
        matesum.push(mat);
        const final = matesum.reduce((a, b) => a + b, 0);
       // console.log(final)
        const payload = {
          "resource": [
      
            {
              "id":prod.id,
              "costprice":final
            }
          ],
        }
     //   console.log(payload)
        patchMaterial(payload).then(res => {
          if (res && res.error) {
            //console.log(res)
          }
          if (res && res.resource[0]) {
           
            return "updated"
          }
        })
      }
    }
  }
  //const final = matesum.reduce((a, b) => a + b, 0);
 
  //return final;
}

export async function getTypes() {
  return await fetch(GET_TYPES, headers)
    .then(response => response.json())
    .then(data => data.resource);
}


export async function createTaskType(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(GET_TYPES, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function deleteTaskType(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "DELETE"
  }
  return await fetch(GET_TYPES, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function getCustOrders(id) {
  if (id) {
    return await fetch(GET_ORDERS + "?filter=customer_id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }

}

export async function getMaterials(id) {
  if (id) {
    return await fetch(GET_PRODUCTS + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (!id) {
    return await fetch(GET_PRODUCTS, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }

}



export async function postMaterial(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(GET_PRODUCTS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function postTask(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(CREATE_TASK, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function patchTask(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(CREATE_TASK, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function postLoan(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(CREATE_LOAN, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function postOrder(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(CREATE_ORDER, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function postProvider(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(GET_PROVIDERS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function postCustomer(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(CREATE_CUSTOMER, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function patchCustomer(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(CREATE_CUSTOMER, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function postPayment(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(PAYMENTS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}


export async function postProduct(data, config = {}) {
  const options = {
    body: JSON.stringify(data
    ),
    headers: headers.headers,
    method: "POST"
  }
  return await fetch(CREATE_PRODUCT, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}



export async function patchOrder(data, config = {}) {
  const options = {
    body: JSON.stringify(data),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(CREATE_ORDER, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function patchProvider(data, config = {}) {
  const options = {
    body: JSON.stringify(data),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(GET_PROVIDERS, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}

export async function patchProduct(data, config = {}) {
  const options = {
    body: JSON.stringify(data),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(CREATE_PRODUCT, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}
export async function updateTaskType(data, config = {}) {
  const options = {
    body: JSON.stringify(data),
    headers: headers.headers,
    method: "PATCH"
  }
  return await fetch(GET_TYPES, options)
    .then(response => response.json())
    .then(data => data).catch(er => console.log(er));
}
export async function postLogin(data, config = {}) {
  const options = {
    body: JSON.stringify({
      email: data.email,
      password: data.password,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Dreamfactory-Api-Key": DFKEY,
    },
    method: "POST"
  }
  return await fetch(POST_LOGIN, options)
    .then(response => response.json())
    .then(data => data);
}

export async function getProducts(id,type) {
  if (id) {
    return await fetch(GET_PRODUCTS + "?filter=id=" + id, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }
  if (type) {
    return await fetch(GET_PRODUCTS + "?filter=usageType=" + type, headers)
      .then(response => response.json())
      .then(data => data.resource);
  }

  return await fetch(GET_PRODUCTS, headers)
    .then(response => response.json())
    .then(data => data.resource);


}



export async function getCustomers() {
  return await fetch(GET_CUSTOMERS, headers)
    .then(response => response.json())
    .then(data => {

      if (data.error) {


        return data.error.code
      }
      return data.resource
    });
}

///TODOOOOO 
export async function getCustomerDetail(id) {
  return await fetch(GET_CUSTOMERS + "?filter=id=" + id, headers)
  .then(response => response.json())
  .then(data => data.resource);
}
export async function getOrders() {
  return await fetch(GET_ORDERS, headers)
    .then(response => response.json())
    .then(data => data.resource);
}

export async function getOrdersbyid(id) {
  return await fetch(GET_ORDERS + "?filter=id=" + id, headers)
    .then(response => response.json())
    .then(data => data.resource);
}
export async function getOrdersbystatus(status) {
  return await fetch(GET_ORDERS + "?filter=status=" + status, headers)
    .then(response => response.json())
    .then(data => data.resource);
}

export async function getTasks() {
  return await fetch(GET_TASKS, headers)
    .then(response => response.json())
    .then(data => data.resource);
}
export async function getTasksbyType(type) {
  return await fetch(GET_TASKS + " AND (type="+type+")", headers)
    .then(response => response.json())
    .then(data => data.resource);
}

export async function getTasksby(id) {
  return await fetch(GET_TASKS + "?filter=orderref=" + id, headers)
    .then(response => response.json())
    .then(data => data.resource);
}


export async function get(url, config = {}) {

}




export async function post(url, data, config = {}) {

}






axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)




export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export default {getSalesPersonsCustomers,getPayments,postPayment, getOrdersbystatus,postProvider, getCodes, getLoans, postLoan,getTasksby,getOrdersbyid,totalCost, patchOrder,patchCustomer,patchTask ,checkOrderStock,getCustomers, postCustomer, postProduct, getMaterials, postMaterial, patchProduct, calccost ,getCustOrders,patchMaterial}