import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem, Table, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { getMaterials } from 'helpers/api_helper'; // Assuming this is your API helper to fetch material details

const ProductModal = ({ isOpen, toggle, product }) => {
  const [requirementsList, setRequirementsList] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState(null); // Track selected requirement
  const [totalCost, setTotalCost] = useState(0); // To track total cost

  // Function to calculate total cost by fetching material cost prices via getMaterials
  const fetchAndCalculateTotalCost = async (parsedRequirements) => {
    try {
      const allMaterialCostsPromises = parsedRequirements.map((requirement) => {
        return Promise.all(
          requirement.materials.map(async (material) => {
            const materialDetails = await getMaterials(material.id); // Fetch material price
            const costprice = materialDetails[0]?.costprice || 0; // Get costprice or fallback to 0
            material.costprice = costprice; // Update costprice in the materials list
            return material.quantity * costprice; // Multiply by quantity
          })
        );
      });

      // Resolve all promises and flatten the results
      const costsArray = await Promise.all(allMaterialCostsPromises);
      const flattenedCosts = costsArray.flat();

      // Calculate total cost by summing all material costs
      const total = flattenedCosts.reduce((accum, cost) => accum + cost, 0);

      setTotalCost(total); // Set total cost
      setRequirementsList(parsedRequirements); // Update requirements with fetched costprice
    } catch (error) {
      console.error("Error fetching material costs:", error);
    }
  };

  // Effect that runs when the modal opens and the product has requirements
  useEffect(() => {
    if (isOpen && product?.requirements) {
      try {
        const parsedRequirements = JSON.parse(product.requirements);
        setSelectedRequirement(null); // Reset selection when modal opens
        fetchAndCalculateTotalCost(parsedRequirements); // Fetch material prices and calculate total cost
      } catch (error) {
        console.error("Error parsing requirements:", error);
      }
    }
  }, [isOpen, product]);

  // Handle price change for each material manually
  const handlePriceChange = (requirementIndex, materialIndex, newPrice) => {
    const updatedRequirements = [...requirementsList];
    updatedRequirements[requirementIndex].materials[materialIndex].price = newPrice; // Update new price
    setRequirementsList(updatedRequirements); // Update state
  };

  // Function to manually calculate the total cost
  const calculateTotalCost = () => {
    const selectedReqMaterials = requirementsList[selectedRequirement].materials;

    const total = selectedReqMaterials.reduce((accum, material) => {
      const price = parseFloat(material.price) || parseFloat(material.costprice) || 0; // Fallback to costprice if no price
      const quantity = parseFloat(material.quantity) || 0;
      return accum + price * quantity;
    }, 0);

    setTotalCost(total); // Set the manually calculated total cost
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Product Details</ModalHeader>
      <ModalBody>
        {/* Product Details */}
        <div>
          <h5>Name: {product?.name}</h5>
          <h5>Cost Price: ${Number(totalCost).toFixed(2)}</h5> {/* Display auto-calculated total cost */}
          <h5>Sales Price: ${Number(product?.salesprice).toFixed(2)}</h5>
          <h5>SKU: {product?.sku}</h5>
        </div>

        {/* Show list of requirements */}
        <h5 className="mt-4">Requirements:</h5>
        <ListGroup>
          {requirementsList.map((requirement, index) => (
            <ListGroupItem
              key={index}
              active={selectedRequirement === index}
              onClick={() => setSelectedRequirement(index)} // Set the clicked requirement as selected
              style={{ cursor: 'pointer' }}
            >
              {requirement.typid} {/* Displaying typeid of each requirement */}
            </ListGroupItem>
          ))}
        </ListGroup>

        {/* If a requirement is selected, show its materials */}
        {selectedRequirement !== null && (
          <>
            <h5 className="mt-4">Materials for {requirementsList[selectedRequirement].typid}:</h5>
            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Material Name</th>
                  <th>Quantity</th>
                  <th>CostPrice</th>
                  <th>New Price</th> {/* Add Price Column */}
                </tr>
              </thead>
              <tbody>
                {requirementsList[selectedRequirement].materials.map((material, matIndex) => (
                  <tr key={matIndex}>
                    <td>{matIndex + 1}</td>
                    <td>{material.name}</td>
                    <td>{material.quantity}</td>
                    <td>{material.costprice}</td> {/* Display the fetched costprice */}
                    <td>
                      {/* Input field to update the price */}
                      <Input
                        type="number"
                        value={material.price || ''} // Display the price, or empty if not set
                        onChange={(e) => handlePriceChange(selectedRequirement, matIndex, e.target.value)} // Update price on change
                        placeholder="Enter price"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Button to manually calculate total cost */}
            <Button color="primary" onClick={calculateTotalCost} className="mt-3">Calculate Total Cost</Button>

            {/* Display the manually calculated total cost */}
            {totalCost > 0 && (
              <h5 className="mt-3">Total Cost: ${totalCost.toFixed(2)}</h5>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

ProductModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

export default ProductModal;
